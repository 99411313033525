<template>
    <div class="case content" v-if="newsPager && newsPager.list.length > 0">
            <div class="case-title">

                <slot name="title"></slot>
                
                <div class="pager">
                    <div class="previous" @click="previous()">&lt;</div>
                    <span>1/{{totalPage}}</span>
                    <div class="next" @click="next()">&gt;</div>
                </div>
            </div>

            <el-carousel class="case-content" ref="swiper">
                <el-carousel-item>
                    <div class="case-list">
                        <template v-for="(item, index) in newsPager.list">
                            <template v-if="item.coverDataType == 1">
                                <div class="case-item" @click="gotoDetail(item.id)">
                                    <div class="case-img">
                                        <img :src="item.coverDataUrl"/>
                                    </div>
                                    <div class="case-name">
                                        {{item.adTitle}}
                                    </div>
                                    <div class="case-date">
                                        {{item.adDateForMat}}
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="case-item" >
                                    <div class="case-img">
                                        <img v-if="item.coverDataType == 1" :src="item.coverDataUrl"/>
                                        <template v-else>
                                            <div class="video" >
                                                <img src="@static/img/play.svg" v-if="!item.isPlaying" @click="playClick('video'+index, index)"/>
                                                <video :src="item.coverDataUrl" :ref="'video'+index"  :style="{filter: !item.isPlaying?'brightness(0.8)':''}"/>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="case-name" @click="gotoDetail(item.id)">
                                        {{item.adTitle}}
                                    </div>
                                    <div class="case-date" @click="gotoDetail(item.id)">
                                        {{item.adDateForMat}}
                                    </div>
                                </div>
                            </template>
                            
                        </template>
                        <template v-if="newsPager.list">
                            <div class="case-item" v-for="(item, index) in 4-newsPager.list.length%4" @click="gotoBuilding">
                                <div class="case-img">
                                </div>
                                <div class="case-name">
                                </div>
                            </div>
                        </template>
                    </div>
                </el-carousel-item>
                   
            
            </el-carousel>
        </div>
</template>

<script>
import {ElCarousel, ElCarouselItem} from 'element-plus'
import {ref, onMounted ,getCurrentInstance} from 'vue';
export default {
    props:{
        newsPager:{
            type: Object,
            default: {}
        },
        title:{
            type: String,
            default: {}
        },
        type: {
            type: String,
            default: ''
        }
    },
    components:{ElCarousel, ElCarouselItem},
    setup(){
        const currentPage = ref(1);
        const totalPage = ref(1);

        const {proxy} = getCurrentInstance();

        function next(){
            proxy.$refs.swiper.next();
        }

        function previous(){
             proxy.$refs.swiper.previous();
        }

        function playClick(name, index){
            console.log(proxy.$refs)
            let item = proxy.newsPager.list[index];
            if(item.isPlaying){
                proxy.$refs[name][0].pause();
                proxy.$refs[name][0].controls=false
            }else{
                proxy.$refs[name][0].play();
                proxy.$refs[name][0].controls=true
            }

            proxy.$refs[name][0].onpause = function(){
                playClick(name, index);
            }
            item['isPlaying'] = !item['isPlaying'];
        }

        return {currentPage, totalPage, next, previous, playClick}
    },
    methods: {
        gotoBuilding(){
            this.$router.push({name:'Building', path: '/building'})
        },
        gotoDetail(id){
            this.$router.push({name: 'NewsDetail', path: '/newsDetail', query:{id: id, type: this.type}})
        }
    }
}
</script>

<style lang="less" scoped>
.case-title{
        font-family: SourceHanSansCN-Medium;
        font-size: 20px;
        line-height: 20px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        text-align: justify;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // border-left: 2px solid  #007EEC;
        padding-left: 3px;
        margin-bottom: 15px;

        .pager{
            float: right;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            text-align: justify;
            .previous, .next{
                border: 1px solid #007EEC;
                line-height: 20px;
                width: 20px;
                text-align: center;
                color: #007EEC;
                cursor: pointer;
            }
            .previous{
                margin-right: 20px;
            }
            .next{
                margin-left: 20px;
            }
            .previous:hover, .next:hover{
                background: #007EEC;
                color: #fff;
            }
            div{
                display:inline-block;
            }
        }
    }
.case-content{
       
        .case-list{
            display: flex;
            align-items: flex-start;

            .case-item{
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 22px;
                .case-img{
                    width: 284px;
                    height: 169px;
                    img{
                        width: 284px;
                        height: 169px;
                        background: #9D9D9D;
                    }

                    .video{
                        background: rgba(0,0,0,0.25);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 284px;
                        height: 169px;
                        position: relative;
                        video{
                            width: 284px;
                            height: 169px;
                            z-index: 1;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        img{
                            width:36px;
                            height: 36px;
                            z-index: 2;
                            background: none;
                            filter: drop-shadow(1px 1px 10px white);
                        }
                    }
                }
                .case-name{
                    font-family: SourceHanSansCN-Medium;
                    font-size: 20px;
                    color: rgba(0,0,0,0.85);
                    letter-spacing: 0;
                    text-align: left;
                    margin-top: 13px;
                }
                .case-date{
                    font-family: SourceHanSansCN-Regular;
                    font-size: 14px;
                    color: rgba(0,0,0,0.50);
                    line-height: 14px;
                    margin-top: 4px;
                }
            }
            .case-item:last-child{
                margin-right: 0px;
            }
        }

        
    }
</style>

